import React from 'react'
import 'twin.macro'

export default function InfoDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"A better way to send money",
        sub_heading:"Transactions",
        left_text:"Ultrices ultricies a in odio consequat egestas rutrum. Ut vitae aliquam in ipsum. Duis nullam placerat cursus risus ultrices nisi, vitae tellus in. Qui non fugiat aut minus aut rerum. Perspiciatis iusto mollitia iste minima soluta id.<br />Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim eget. Est augue maecenas risus nulla ultrices congue nunc tortor. Eu leo risus porta integer suspendisse sed sit ligula elit.<br />Integer varius imperdiet sed interdum felis cras in nec nunc.<br />Quam malesuada odio ut sit egestas. Elementum at porta vitae.<br />Amet, eu nulla id molestie quis tortor. Auctor erat justo, sed pellentesque scelerisque interdum blandit lectus. Nec viverra amet ac facilisis vestibulum. Vestibulum purus nibh ac ultricies congue.<br />",
        right_text:"Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim eget. Est augue maecenas risus nulla ultrices congue nunc tortor.<br />Eu leo risus porta integer suspendisse sed sit ligula elit. Elit egestas lacinia sagittis pellentesque neque dignissim vulputate sodales. Diam sed mauris felis risus, ultricies mauris netus tincidunt. Mauris sit eu ac tellus nibh non eget sed accumsan. Viverra ac sed venenatis pulvinar elit. Cras diam quis tincidunt lectus. Non mi vitae, scelerisque felis nisi, netus amet nisl.<br />Eu eu mauris bibendum scelerisque adipiscing et. Justo, elementum consectetur morbi eros, posuere ipsum tortor. Eget cursus massa sed velit feugiat sed ut. Faucibus eros mauris morbi aliquam nullam. Scelerisque elementum sit magna ullamcorper dignissim pretium.<br />",
       ...finalData
  } 
    return (
            <div tw="py-8 xl:py-12 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden w-full">
            <div tw="max-w-full lg:max-w-7xl mx-auto">
                <div tw="relative z-10 mb-8 md:mb-2 md:px-6">
                <div tw="text-base max-w-full lg:max-w-none">
                    <h2 tw="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{content.sub_heading}</h2>
                    <p tw="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{content.heading}</p>
                </div>
                </div>
                <div tw="relative">
                <svg tw="hidden md:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                    <defs>
                    <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                    </pattern>
                    </defs>
                    <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
                </svg>
                <svg tw="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                    <defs>
                    <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                    </pattern>
                    </defs>
                    <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
                </svg>
                <div tw="relative md:bg-white md:p-6">
                    <div tw="md:grid md:grid-cols-2 md:gap-6">
                        <div tw="text-gray-500 lg:max-w-none" dangerouslySetInnerHTML={{__html:content.left_text}}></div>
                        <div tw="mt-6 text-gray-500 lg:mt-0" dangerouslySetInnerHTML={{__html:content.right_text}}></div>
                    </div>
                </div>
                </div>
            </div>
            </div>

    )
}
